<template>
  <AView
    :value="value"
    :active="deliverable.id === activeDeliverableId"
    :name="deliverable.name"
    @onHover="onHover"
    @onActivate="(e) => $emit('onActivate', e)"
    @onMaximize="(e) => $emit('onMaximize', e)"
    @onExpand="(e) => $emit('onExpand', e)"
    @onCollapse="(e) => $emit('onCollapse', e)"
    @onClose="(e) => $emit('onClose', e)"
  >
    <template v-slot:content="{}">
      <v-card class="transparent ma-0 pa-0 elevation-0">
        <v-card-text>
          <DeliverableGeneralEditorFormVue
            ref="form"
            v-model="deliverable"
          ></DeliverableGeneralEditorFormVue>
        </v-card-text>
      </v-card>
    </template>
  </AView>
</template>
      
      
  <script>
import { mapGetters, mapState } from "vuex";
import DeliverableGeneralEditorFormVue from "../../../../../../../components/wad/organisms/deliverables/forms/editor/DeliverableGeneralEditorForm.vue";
import AView from "../../../../../../../components/wad/organisms/layout/A-View.vue";

// Deliverable
/* eslint-disable no-unused-vars */

/* eslint-enable no-unused-vars */

export default {
  props: {
    value: {
      default: () => ({}),
    },
  },
  components: {
    AView,
    //   FormSection,
    DeliverableGeneralEditorFormVue,
  },
  data() {
    return {
      deliverable: {},
    };
  },
  computed: {
    ...mapState("DeliverableStore", [
      "activeDeliverableId",
      "displayedDeliverables",
    ]),
    ...mapGetters("DeliverableStore", ["deliverableById"]),
  },
  async created() {
    await this.$store.dispatch("DeliverableStore/GetDeliverable", {
      id: this.value.relation.params.deliverableId,
    });

    this.deliverable = this.deliverableById(
      this.value.relation.params.deliverableId
    );
  },
  methods: {
    onHover() {
      this.$store.commit(
        "DeliverableStore/setActiveId",
        this.value.relation.params.deliverableId
      );
    },
  },

  watch: {
    displayedDeliverables: {
      async handler(newVal) {
        if (newVal && !newVal.IN_PROGRESS) {
          this.deliverable = Object.assign(
            this.deliverable,
            this.deliverableById(this.value.relation.params.deliverableId)
          );
        }
      },
      deep: true,
    },
    deliverable: {
      async handler(newVal, oldVal) {
        if (!(oldVal && oldVal.id)) return;

        if (!this.$refs.form.validate()) return;

        await this.$store.dispatch("DeliverableStore/UpdateDeliverable", {
          deliverable: newVal,
        });
      },
      deep: true,
    },
  },
};
</script>