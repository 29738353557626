import { render, staticRenderFns } from "./DeliverableDocumentForm.vue?vue&type=template&id=cdce4228&scoped=true&"
import script from "./DeliverableDocumentForm.vue?vue&type=script&lang=js&"
export * from "./DeliverableDocumentForm.vue?vue&type=script&lang=js&"
import style0 from "./DeliverableDocumentForm.vue?vue&type=style&index=0&id=cdce4228&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cdce4228",
  null
  
)

export default component.exports