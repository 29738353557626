<template>
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-row>
        <v-col cols="12" class="deliverable-from-col pa-0 ma-0">
          <NumberInputVue
            dark
            :valeFormatter="(val) => timeFormatter(val, 'm')"
            :step="30"
            :quantityMeasure="'m'"
            :quantityMeasureHelpText="'Minutes'"
            v-model="form.avg_time"
            :helpText="'This is an average time to prepare the document'"
          ></NumberInputVue>
        </v-col>
      </v-row>
    </v-form>
  </template>
      
      
  <script>
  import { FormatterHelper } from "../../../../../../helpers/formatter.helper";
  import NumberInputVue from "../../../../../atoms/common/NumberInput.vue";
  
  export default {
    props: {
      value: {
        default: () => ({}),
      },
      steps: {
        type: Array,
        default: () => [],
      },
    },
    data() {
      console.log("This value: ", this.value);
  
      return {
        valid: true,
        tab: "general",
        form: this.value,
        timeFormatter: FormatterHelper.timeDuration,
  
        // ======rules
        serviceDescriptionRules: [(v) => !!v || "Description is required"],
        select: null,
        stepsTypes: ["tabs", "slider", "without_steps"],
        checkbox: false,
      };
    },
    components: {
      // HelpFormInputVue,
      NumberInputVue,
    },
    methods: {
      validate() {
        return this.$refs.form.validate();
      },
      reset() {
        this.$refs.form.reset();
      },
      resetValidation() {
        this.$refs.form.resetValidation();
      },
      onAddStep() {
        this.$emit("onAdd", this.v);
      },
      onStepRemove(index) {
        this.from.steps.splice(index, 1);
      },
    },
    watch: {
      value(newVal) {
        this.form = newVal;
      },
      form: {
        handler(newVal) {
          this.$emit("onChange", newVal);
        },
        deep: true,
      },
    },
  };
  </script>
  
  <style lang="scss" scoped>
  .deliverable-from-col {
    display: flex;
    flex-direction: column;
    min-width: 200px;
    flex-grow: 1;
    max-width: 100%;
  }
  </style>