<template>
  <v-form ref="form" v-model="valid" lazy-validation>
    <v-row>
      <v-col cols="12" lg="6" sm="6" md="6" class="deliverable-from-col pa-0 ma-0">
        <SelectorDefaultVue
          dark
          label="Activity Type"
          v-model="form.type"
          :items="activityTypes"
          :helpText="'This is activity Type. You can specify the type and then the system will allow you to include this deliverable into services pipeline.'"
        >
        </SelectorDefaultVue>
      </v-col>
      <v-col cols="12" lg="6" sm="6" md="6" class="deliverable-from-col pa-0 ma-0">
        <NumberInputVue
          dark
          :valeFormatter="(val) => timeFormatter(val, 'm')"
          :step="30"
          :quantityMeasure="'m'"
          :quantityMeasureHelpText="'Minutes'"
          v-model="form.avg_time"
          :helpText="'This is an average time to prepare this deliverable'"
        ></NumberInputVue>
      </v-col>
    </v-row>
  </v-form>
</template>
    
    
<script>
import { FormatterHelper } from "../../../../../../helpers/formatter.helper";
import SelectorDefaultVue from "../../../../../atoms/common/autocompletes/SelectorDefault.vue";
// import HelpFormInputVue from "../../../atoms/common/inputs/HelpFormInput.vue";
import NumberInputVue from "../../../../../atoms/common/NumberInput.vue";

// import HelpFormSwitchVue from "../../atoms/common/inputs/HelpFormSwitch.vue";

export default {
  props: {
    value: {
      default: () => ({}),
    },
    steps: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    console.log("This value: ", this.value);

    return {
      valid: true,
      tab: "general",
      form: this.value,
      timeFormatter: FormatterHelper.timeDuration,

      activityTypes: [
        {
          name: "Meeting",
          value: "MEETING",
          description:
            "This is activity is a meeting. So, if the deliverable expects a meeting with a customer this type is applicable. ",
        },
        {
          name: "Process",
          value: "PROCESS",
          description:
            "This is a some kind of process, e.g. document preparation, some kind of analysis, etc. If the deliverable expects some activity this type is applicable",
        },
      ],

      // ======rules
      nameRules: [(v) => !!v || "Section Name is required"],

      serviceLevelRules: [(v) => !!v || "Service Level is required"],
      serviceNameRules: [(v) => !!v || "Service Name is required"],
      serviceCodeRules: [(v) => !!v || "Service Name is required"],
      serviceBtnRules: [(v) => !!v || "Action Button Text is required"],
      serviceDescriptionRules: [(v) => !!v || "Description is required"],
      select: null,
      stepsTypes: ["tabs", "slider", "without_steps"],
      checkbox: false,
    };
  },
  components: {
    // HelpFormInputVue,
    NumberInputVue,
    SelectorDefaultVue,
  },
  methods: {
    validate() {
      return this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    onAddStep() {
      this.$emit("onAdd", this.v);
    },
    onStepRemove(index) {
      this.from.steps.splice(index, 1);
    },
  },
  watch: {
    value(newVal) {
      this.form = newVal;
    },
    form: {
      handler(newVal) {
        this.$emit("onChange", newVal);
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.deliverable-from-col {
  display: flex;
  flex-direction: column;
  min-width: 200px;
  flex-grow: 1;
  max-width: 100%;
}
</style>