<template>
  <v-form ref="form" v-model="valid" lazy-validation v-bind="$attrs">
    <v-row class="ma-0 pa-0">
      <v-col
        cols="12"
        lg="6"
        sm="6"
        md="6"
        class="deliverable-from-col pa-0 ma-0"
      >
        <SelectorDeliverableStatuses
          dark
          autoload
          label="Deliverable Status"
          helpText="This is an actual Deliverable Status"
          return-object
          v-model="form.status"
        >
        </SelectorDeliverableStatuses>

        <HelpFormInputVue
          dark
          v-model="form.name"
          :rules="nameRules"
          label="Deliverable Name"
          required
          :helpText="'This is a name of the new section'"
        ></HelpFormInputVue>
        <HelpFormInputVue
          dark
          :rows="4"
          textarea
          v-model="form.definition"
          label="Deliverable Definition"
          required
          :helpText="'This is a deliverable definition. \nThis information for the internal use only.'"
        ></HelpFormInputVue>

        <HelpFormInputVue
          dark
          :rows="4"
          textarea
          v-model="form.goal"
          label="Deliverable Goal"
          required
          :helpText="'Please provide why this deliverable may be needed from the business perspectives'"
        ></HelpFormInputVue>
      </v-col>
      <v-col
        cols="12"
        lg="6"
        sm="6"
        md="6"
        class="deliverable-from-col pa-0 ma-0"
      >
        <SelectorIconsVue
          dark
          v-model="form.icon"
          label="Deliverable Icon"
          :helpText="'You can specify custom icon for deliverable to be shown on the list'"
        >
        </SelectorIconsVue>

        <HelpFormInputVue
          dark
          :rows="4"
          textarea
          v-model="form.marketing_description"
          label="Deliverable Marketing Description"
          required
          :helpText="'This is customer faced deliverable description'"
        ></HelpFormInputVue>

        <HelpFormInputVue
          dark
          :rows="4"
          textarea
          v-model="form.legal_description"
          label="Deliverable Legal Description"
          required
          :helpText="'This is a legal related deliverable definition. It will be used for the SLA document generation. \nThis information for the internal use only.'"
        ></HelpFormInputVue>
      </v-col>
    </v-row>
    <v-row v-if="form.activity">
      <v-col class="deliverable-from-col pa-0 ma-0">
        <DeliverableActivityForm
          ref="activityForm"
          v-model="form.activity"
        ></DeliverableActivityForm>
      </v-col>
    </v-row>
    <v-row v-if="form.document">
      <v-col class="deliverable-from-col pa-0 ma-0">
        <DeliverableDocumentForm
          ref="activityForm"
          v-model="form.document"
        ></DeliverableDocumentForm>
      </v-col>
    </v-row>
  </v-form>
</template>
    
    
    <script>
import SelectorDeliverableStatuses from "../../../../atoms/common/autocompletes/SelectorDeliverableStatuses.vue";
import SelectorIconsVue from "../../../../atoms/common/autocompletes/SelectorIcons.vue";
import HelpFormInputVue from "../../../../atoms/common/inputs/HelpFormInput.vue";
import DeliverableActivityForm from "./types/DeliverableActivityForm.vue";
import DeliverableDocumentForm from "./types/DeliverableDocumentForm.vue";

// import HelpFormSwitchVue from "../../atoms/common/inputs/HelpFormSwitch.vue";

export default {
  props: {
    value: {
      default: () => ({}),
    },
    steps: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    console.log("This value: ", this.value);

    return {
      valid: true,
      form: this.value,

      // ======rules
      nameRules: [(v) => !!v || "Deliverable Name is required"],
    };
  },
  components: {
    HelpFormInputVue,
    SelectorIconsVue,
    DeliverableActivityForm,
    DeliverableDocumentForm,
    SelectorDeliverableStatuses,
  },
  methods: {
    validate() {
      console.log("VALID? ", this.$refs.form.validate());
      return this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    onAddStep() {
      this.$emit("onAdd", this.v);
    },
    onStepRemove(index) {
      this.from.steps.splice(index, 1);
    },
  },
  watch: {
    value(newVal) {
      this.form = newVal;
    },
    form: {
      handler(newVal) {
        this.$emit("input", newVal);
      },
      deep: true,
    },
  },
};
</script>
  
  
  <style lang="scss" scoped>
.deliverable-from-col {
  display: flex;
  flex-direction: column;
  min-width: 200px;
  flex-grow: 1;
  max-width: 100%;
}
</style>